import request from '@/utils/request'

export function getDepartmentList() {
    return request({
        url: '/api/oauth/department/',
        method: 'get'
    })
}

export function getUserInfo() {
    return request({
        url: '/api/oauth/user_detail/',
        method: 'get'
    })
}

export function getUserInfoByManager(data) {
    return request({
        url: '/api/oauth/usermanage/' + data.id + '/',
        method: 'get'
    })
}

export function updateUserInfo(data) {
    return request({
        url: '/api/oauth/usermanage/' + data.id + '/',
        method: 'patch',
        data
    })
}

export function updateUserInfoByManager(data) {
    return request({
        url: '/api/oauth/usermanage/' + data.id + '/',
        method: 'put',
        data
    })
}

export function getUserList(data) {
    return request({
        url: `/api/oauth/user/?onsite=${data.onsite}`,
        method: 'get',
    })
}

export function getUserListByDepartment() {
    return request({
        url: `/api/oauth/departmentusers/`,
        method: 'get',
    })
}

export function getAllUsers(data) {
    return request({
        url: `/api/oauth/organization/getall_user/`,
        method: 'post',
        data
    })
}

export function getCompanyChart() {
    return request({
        url: `/api/config/company/get_chart/`,
        method: 'get',
    })
}

export function getProjectMembers(params) {
    return request({
        url: `/api/oauth/projectuser/`,
        method: 'get',
        params
    })
}

