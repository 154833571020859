<template>
  <div v-if="!item.hidden&&item.children" class="menu-wrapper">
    <!-- 只有一个子任务显示 -->
    <router-link
      v-if="hasOneShowingChild(item.children) && !onlyOneChild.children&&!item.alwaysShow"
      :to="resolvePath(onlyOneChild.path)"
    >
      <el-menu-item
        :index="resolvePath(onlyOneChild.path)"
        :class="{'submenu-title-noDropdown':!isNest}"
      >
        <svg-icon
          v-if="onlyOneChild.meta&&onlyOneChild.meta.icon"
          :icon-class="onlyOneChild.meta.icon"
        ></svg-icon>
        <span
          v-if="onlyOneChild.meta&&onlyOneChild.meta.title"
          slot="title"
        >{{onlyOneChild.meta.title}}</span>
      </el-menu-item>
    </router-link>
    <!-- 只有多个子任务显示 -->
    <el-submenu v-else :index="item.name||item.path">
      <!-- 父主题 -->
      <template slot="title">
        <svg-icon v-if="item.meta&&item.meta.icon" :icon-class="item.meta.icon"></svg-icon>
        <span v-if="item.meta&&item.meta.title" slot="title">{{item.meta.title}}</span>
      </template>

      <template v-for="(child,index) in item.children" v-if="!child.hidden">
        <sidebar-item
          :is-nest="true"
          class="nest-menu"
          v-if="child.children&&child.children.length>0"
          :item="child"
          :key="child.path"
          :base-path="resolvePath(child.path)"
        ></sidebar-item>

        <router-link v-else :to="resolvePath(child.path)" :key="child.name">
          <el-menu-item
            :index="resolvePath(child.path)"
            :class="[index==0 ? 'item_radius' : '', index ==Number(item.children.length-1) ? 'item_radius_bottom' :'']"
          >
            <svg-icon v-if="child.meta&&child.meta.icon" :icon-class="child.meta.icon"></svg-icon>
            <span
              v-if="child.meta&&child.meta.title"
              slot="title"
              style="color:"
            >{{child.meta.title}}</span>
            <span class="text" v-if="child.meta&&child.meta.count" slot="title">{{child.meta.count}}</span>
          </el-menu-item>
        </router-link>
      </template>
    </el-submenu>
  </div>
</template>

<script>
import path from "path";

export default {
  name: "SidebarItem",
  props: {
    // route配置json
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      onlyOneChild: null
    };
  },
  mounted() {
    // getRouteCount({
    //   f: "mytask",
    //   did: 16
    // })
    //   .then(res => {
    //     this.count = res.data;
    //   })
    //   .catch(err => {});
  },
  // created(){
  //   console.log(this.item.children.length)
  // },
  methods: {
    hasOneShowingChild(children) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false;
        } else {
          // temp set(will be used if only has one showing child )
          this.onlyOneChild = item;
          return true;
        }
      });
      if (showingChildren.length === 1) {
        return true;
      }
      return false;
    },
    resolvePath(...paths) {
      return path.resolve(this.basePath, ...paths);
    }
  }
};
</script>

<style lang="scss" scoped>
.item_radius {
  border-radius: 16px 16px 0 0;
}
.item_radius_bottom {
  border-radius: 0 0 16px 16px;
}
</style>