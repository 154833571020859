<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
let socket = "";
let path = "wss://pm.yichenghbkj.com/websocketReg";
let lockReconnect = false;
//心跳检测
var heartCheck = {
  timeout: 1000 * 5, //s发一次心跳
  timeoutObj: null,
  serverTimeoutObj: null,
  reset: function() {
    clearTimeout(this.timeoutObj);
    clearTimeout(this.serverTimeoutObj);
    return this;
  },
  start: function() {
    var self = this;
    this.timeoutObj = setTimeout(function() {
      //这里发送一个心跳，后端收到后，返回一个心跳消息，
      //onmessage拿到返回的心跳就说明连接正常
      if (socket.readyState == 1) {
        socket.send("ping");
        // console.log("ping!");
      }
      self.serverTimeoutObj = setTimeout(function() {
        //如果超过一定时间还没重置，说明后端主动断开了
        socket.close(); //如果onclose会执行reconnect，我们执行ws.close()就行了.如果直接执行reconnect 会触发onclose导致重连两次
      }, self.timeout);
    }, this.timeout);
  }
};
import { getToken, setUserInfo } from "@/utils/auth";
import { getUserInfo } from "@/api/user";

export default {
  name: "App",
  methods: {
    initSocket() {
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持socket");
      } else {
        // console.log("this.$root.ws...", this.$root.ws?.readyState);
        if (
          !this.$root.ws ||
          (this.$root.ws && this.$root.ws.readyState == 3)
        ) {
          console.log("socket初始中...");
          // 实例化socket
          socket = new WebSocket(path);
          // 监听socket连接
          socket.onopen = this.open;
          // 监听socket错误信息
          socket.onerror = this.error;
          // 监听socket消息
          socket.onmessage = this.getMessage;
          socket.onclose = this.close;
          this.$root.ws = socket;
        } else {
          console.log("socket已连接...");
          socket = this.$root.ws;
          console.log("socket.readyState...", socket.readyState);
        }
      }
    },
    reconnect() {
      if (lockReconnect) return;
      lockReconnect = true;
      setTimeout(() => {
        this.initSocket();
        lockReconnect = false;
      }, 2000);
    },
    open() {
      heartCheck.reset().start();
      console.log("socket连接成功");
      this.getNoticeList();
    },
    error(err) {
      console.log(err);
      console.log("连接错误");
      this.reconnect();
    },
    getMessage(msg) {
      heartCheck.reset().start();
      if (msg.data == "pong") {
        // console.log(msg.data);
        return;
      }
      console.log("ws data...", msg.data);
      let data = JSON.parse(msg.data);
      this.messageData = data;
      this.send();
      if (data.cmd == "refresh") {
        // console.log("刷新");
        this.getNoticeList();
      } else if (data.cmd == "user_refresh") {
        this.Pleasecache();
      } else if (data.cmd == "refresh_announcement") {
        this.getAfficheList();
      } else {
        // console.log("不刷新");
      }
    },
    getNoticeList() {
      this.$root.eventHub.$emit("getNoticeList", null);
    },
    // 通知公告组件刷新列表
    getAfficheList() {
      this.$root.eventHub.$emit("getAfficheList", null);
    },
    send() {
      if (this.messageData.sole_id) {
        let params = `{"type":"register","token":"${getToken()}","sole_id":"${
          this.messageData.sole_id
        }"}`;
        console.log(params);
        socket.send(params);
        console.log("发送成功！！！！");
      }
    },
    close() {
      this.reconnect();
      console.log("socket已经关闭");
    },
    Pleasecache() {
      getUserInfo()
      .then(res => {
        console.log('setUserInfo...',res.data);
        setUserInfo(res.data);
        sessionStorage.removeItem("DynamicRoutes");
        location.reload();
      })
      .catch(err => {});
   
    }
  },
  mounted() {
    setTimeout(() => {
      this.initSocket();
    }, 1000);
    // this.initSocket();
    const convertStyle = () => {
      let vh = window.innerHeight * 0.01;
      // console.log('vh...', vh);
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    window.addEventListener("resize", convertStyle);
    window.addEventListener("DOMContentLoaded", convertStyle);
  }
};

const debounce = (fn, delay) => {
  let timer = null;

  return function() {
    let context = this;

    let args = arguments;

    clearTimeout(timer);

    timer = setTimeout(function() {
      fn.apply(context, args);
    }, delay);
  };
};

// 解决ERROR ResizeObserver loop completed with undelivered notifications.

const _ResizeObserver = window.ResizeObserver;

window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);

    super(callback);
  }
};
</script>

<style lang="scss">
@import "./assets/font/font.css";
#app {
  font-family: "HarmonyOS_Sans_SC";
  font-weight: bold;
  /* border: 1px solid #B7C2D2; */
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "HarmonyOS_Sans_SC" !important;
  // font-weight: 500;
  /* font-weight: bold; */
}
</style>
