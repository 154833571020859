import Cookies from 'js-cookie'
const TokenKey = 'Admin-Token'
const RefreshToken = 'refresh_token'

const NameKey = 'Admin-Name'
const DynamicRoutes = 'DynamicRoutes'

const HeadImg = 'headimg'
const UserInfo = 'userInfo'

export function getToken() {
  return localStorage.getItem(TokenKey)
  // return Cookies.get(TokenKey)
}

export function setToken(token) {
  localStorage.setItem(TokenKey, token)
  // return Cookies.set(TokenKey, token)
}

export function removeToken() {
  localStorage.removeItem(TokenKey)
  localStorage.removeItem(RefreshToken)
}

export function getRefreshToken() {
  return localStorage.getItem(RefreshToken)
}

export function setRefreshToken(refresh_token) {
  localStorage.setItem(RefreshToken, refresh_token)
  // return Cookies.set(TokenKey, token)
}

export function setName(name) {
  localStorage.setItem(NameKey, name)
  // return Cookies.set(NameKey, name)
}

export function getName() {
  return localStorage.getItem(NameKey)
  // return Cookies.get(NameKey)
}

export function getUserName() {
  let userinfo = JSON.parse(localStorage.getItem(UserInfo));
  return userinfo.name
}

export function setHeadImg(headImg) {
  let userinfo = JSON.parse(localStorage.getItem(UserInfo));
  userinfo.headimg = headImg;
  localStorage.setItem(UserInfo, JSON.stringify(userinfo))
}

export function getHeadImg() {
  let userinfo = JSON.parse(localStorage.getItem(UserInfo));
  return userinfo.headimg
}

export let isManager = () => { return getLevel() == '1' } // 管理员
export let isStaff = () => { return getLevel() == '2' } // 员工
export let isDirector = () => { return getLevel() == '4' } // 主管
export let isSuperManager = () => { return getLevel() == '8'} // 超级管理员

export function getLevel() {
  let userinfo = JSON.parse(localStorage.getItem(UserInfo));
  return userinfo.level
}

export function getUid() {
  let userinfo = JSON.parse(localStorage.getItem(UserInfo));
  return userinfo.id
}

export function getIsFollow() {
  let userinfo = JSON.parse(localStorage.getItem(UserInfo));
  return userinfo.is_follow
}


export function getIsApprove() {
  let userinfo = JSON.parse(localStorage.getItem(UserInfo));
  return userinfo.is_approve
}


export function getUserInfo() {
  return JSON.parse(localStorage.getItem(UserInfo))
}

export function setUserInfo(user) {
  localStorage.setItem(UserInfo, JSON.stringify(user))
}


export function getDynamicRoutes() {
  return JSON.parse(sessionStorage.getItem(DynamicRoutes))
}

export function setDynamicRoutes(routes) {
  sessionStorage.setItem(DynamicRoutes, JSON.stringify(routes))
}