<template>
    <div class="filetype">
        <!-- 上传回显 -->
        <div class="file" v-for="(item, index) of FilesList"   v-on:mouseenter="handleMouseEnter(index)"
            v-on:mouseleave="handleMouseLeave(index)" :key="item.id" v-if="Type == 1 ">
            <div class="delete"  @click.stop="clickDel2(index)">+</div>
            <div class="inner2">
                <img :src="item.isImg ? item.url : item.fileType" alt />
                <!-- <el-image style="width: 48px; height: 480px" :src="item.isImg ? item.url : item.fileType" ></el-image> -->
                <div class="fileName">{{ item.name }}</div>
            </div>
        </div>
        <!-- 只显示 -->
        <div class="file files" v-for="(item) of FilesList" :key="item.id" v-if="Type == 2">
            <div class="inner2" @click="preview(item)">
                <img :src="item.isImg ? item.local_url : item.fileType" alt />
                <!-- <el-image v-else class="pic"
                            :preview-src-list="srcList"
                            :src="item.isImg ? item.local_url : item.fileType" ></el-image> -->
                <div class="fileName">{{ item.name }}</div>
            </div>
        </div>
        <!-- 笔记文件 -->
        <div class="file noteFiles" v-for="(item) of FilesList" :key="item.id" v-if="Type == 3 && item.filetype != 'image' && item.filetype != 'video'">
            <div class="inner2">
                <img :src="item.isImg ? item.local_url : item.fileType" alt />
                <div class="fileName">{{ item.name }}</div>
            </div>
        </div>
        <div class="file files" v-for="(item) of FilesList" :key="item.id" v-if="Type == 4">
            <div class="inner2" @click="preview(item)">
                <img :title="item.official_name" :src="item.isImg ? item.local_url : item.fileType" alt />
                <!-- <el-image v-else class="pic"
                            :preview-src-list="srcList"
                            :src="item.isImg ? item.local_url : item.fileType" ></el-image> -->
                <div class="fileName">{{ item.official_name }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import pdf from "@/assets/file/pdf.png";
import excel from "@/assets/file/excel.png";
import ppt from "@/assets/file/ppt.png";
import link from "@/assets/file/link.png";
import unknown from "@/assets/file/unknown.png";
import word from "@/assets/file/word.png";
import zip from "@/assets/file/zip.png";
export default{
    name:'FileType',
    props:{
        Files:{
            type:Array
        },
        Type:{
            type:Number,
            // default:"1"
        },
        filesType:{
            type:String,
            default:'files'
        }
    },
    data() {
        return {
            pdf,
            excel,
            ppt,
            unknown,
            link,
            word,
            zip,
            
            srcList: [
            // 'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
            // 'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
            ],
            show:false
        }
    },
    // watch:{
    //     Files: {
    //         handler(val) {
    //             this.Files = val.map(e => {
    //                 e.isImg = this.checkImgType(e);
    //                 e.fileType = this.checkFileType(e);
    //                 return e;
    //             });
    //         },
    //     },
    // },
    computed: {
        FilesList() {
            return this.Files.map(e => {
                e.isImg = this.checkImgType(e);
                e.fileType = this.checkFileType(e);
                if(e.isImg){
                    this.srcList.push(e.local_url)
                }else{
                    this.srcList.push(e.filetype)
                }
                return e;
            });

        }
        
    },
    methods:{
        checkImgType(file) {
            let name = file.id > 0 ? file.origin_name : file.name;
            // console.log("name...", file);
            //用文件名name后缀判断文件类型，可用size属性判断文件大小不能超过500k ， 前端直接判断的好处，免去服务器的压力。
            if (!/\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(name)) {
                return false;
            } else {
                return true;
            }
        },
        checkFileType(file) {
            file.name = file.id > 0 ? file.origin_name : file.name;
            if (/\.(pdf)$/.test(file.name)) {
                return pdf;
            } else if (/\.(ppt)$/.test(file.name)) {
                return ppt;
            } else if (/\.(excel)$/.test(file.name)) {
                return excel;
            } else if (/\.(word)$/.test(file.name)) {
                return word;
            } else if (/\.(js|java|vue|html|css)$/.test(file.name)) {
                return link;
            } else if (/\.(zip|rar)$/.test(file.name)) {
                return zip;
            } else {
                return unknown;
            }
        },
        clickDel2(val){
            console.log(val)
            this.$emit('deleteFile',val)
        },
        handleMouseEnter(index){
            console.log('11111',index);
            // this.show = true
            // this.FilesList[index].show = true
            this.$set(this.FilesList[index], "show", true);
             console.log( this.FilesList[index].show);
        },
        handleMouseLeave(index){
            console.log("2222",index);
            // this.show = false
            // this.FilesList[index].show = false
            // console.log( this.FilesList[index].show);
            this.$set(this.FilesList[index], "show", false);
             console.log( this.FilesList[index].show);
        },
        preview(item){
            console.log(item)
            let url = item.local_url
            // let url = "https://dc.yichenghbkj.com:18001//uploads/24-03-22/测试视频.mp4"
            window.open(url,'_blank')
        }
    }
}
</script>

<style lang="scss" scoped>
.filetype{
    display: flex;
    // justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0 16px;
}
  .file {
    width: 64.2px;
    position: relative;
    margin-bottom: 12px;
    margin:4px;
    // border: 1px solid #000;
    .inner1,
    // .inner2 {
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: center;
    //   align-items: center;
    // }
    // .filesImg{
    //     width: 48px;
    //     height: 48px;
    //     // img{
    //     //     width: 100%;
    //     //     height: 100%;
    //     //     display: block;
    //     // }
    // }
    img {
        width: 48px;
        height: 48px;
        // display: block;
        border-radius: 6.545px;
        display: flex;
        text-align: center;
        margin: 0 auto;
        // margin-right: 20px;
        // border: 1px solid #000;
    }
    .fileName {
      color: #103268;
      text-align: center;
      text-overflow: ellipsis;
      font-size: 10px;
      word-break: break-all;
    }
  }
  .delete{
    // content: "+";
    width: 14px;
    height: 14px;
    line-height: 12px;
    display: block;
    position: absolute;
    text-align: center;
    border-radius: 7px;
    font-size: 12px;
    transform: rotate(45deg);
    top: 0;
    right: -5px;
    color: #fff;
    background: #103268;
    opacity: 0.4;
    cursor: pointer;
  }
  .noteFiles{
    // border: 1px solid #103268;
    margin: 0;
    margin-right: 26px;
  }
  .files:hover::after{
    display:none
  }
  ::v-deep .el-image{
    border-radius:6.545px
  }
</style>