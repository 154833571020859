<template>
    <!-- <div>
    <span>海驰设计研发部</span>
    <div v-for="item in routes" :key="item.id">
      <NewSideBar :route="item" :show-children="false"></NewSideBar>
    </div>
  </div> -->
  <!-- <el-scrollbar  wrap-style="overflow-x:hidden;" wrapClass="scrollbar-wrapper">  -->
  <!-- :collapse="isCollapse" -->

  <el-menu
    mode="vertical"
    :show-timeout="200"
    :default-active="$route.path"
    background-color="#fff"
    text-color="#103268"
    active-text-color="#103268"
    unique-opened
    @open="handleOpen"
  >
    <el-menu-item index="1" class="el-menu-item_title">
      <svg-icon icon-class="logo"></svg-icon>
      <span slot="title">海驰设计研发部</span>
    </el-menu-item>
    <div class="infinite-list" style="overflow:auto">
      <sidebar-item v-for="route in routes" :key="route.name" :item="route" :base-path="route.path"></sidebar-item>
    </div>
  </el-menu>
  <!-- </el-scrollbar> -->
</template>

<script>
import router from "@/route/index";
import { mapGetters } from "vuex";
import SidebarItem from "./SidebarItem";
import NewSideBar from "@/views/layout/components/Sidebar/newSideBar.vue";

import variables from "@/styles/variables.scss";
import { mymenu } from "@/api/auth";
import { fnAddDynamicMenuRoutes } from "@/route/getAsyncRouter";
import { setDynamicRoutes, getDynamicRoutes } from "@/utils/auth";

export default {
  components: { SidebarItem, NewSideBar },
  mounted() {
    this.$root.eventHub.$on("update-routes", () => {
      this.getMenus();
    });
  },
  data() {
    return {
      myRouter: router
    };
  },
  computed: {
    ...mapGetters(["sidebar"]),
    routes() {
      return this.myRouter.options.routes;
    },
    isCollapse() {
      return !this.sidebar.opened;
    }
  },
  methods: {
    variables() {
      return variables;
    },
    getMenus() {
      mymenu()
        .then(res => {
          setDynamicRoutes(res.data);
          router.options.routes = [];
          let menuRoutes = fnAddDynamicMenuRoutes(res.data);
          menuRoutes.forEach(item => {
            router.options.routes.push(item);
          });
        })
        .catch(err => {});
    },
    handleOpen(path) {
      let routes = getDynamicRoutes();
      routes.forEach(item => {
        if (item.path === path && item.children && item.children.length > 0) {
          this.$router
            .push({
              name: item.children[0].name
            })
            .catch(err => {});
        }
      });
      // console.log("getDynamicRoutes...", getDynamicRoutes());
      // console.log("handleOpen", args);
    }
  }
};
</script>
<style lang="scss">
::v-deep .el-scrollbar__bar .is-vertical {
  overflow-x: hidden;
}
::v-deep .sidebar-wrapper .el-scrollbar__wrap {
  overflow-x: hidden;
}
.el-menu-item_title {
}
.infinite-list {
  height: vh(968);
}
.infinite-list::-webkit-scrollbar {
  width: 0;
}
</style>

