import Vue from 'vue'

import '@/utils/elementUI'
import 'element-ui/lib/theme-chalk/index.css';

import '@/styles/index.scss' // global css

import App from './App.vue';
import router from './route';
import './route/permission'
import store from './store'
import axios from "axios" //npm i axios -S

import '@/icons' // icon

// import './utils/rem'
import 'amfe-flexible';

import MasonryWall from '@yeger/vue2-masonry-wall'
import VueCropper from 'vue-cropper'
Vue.use(VueCropper)
Vue.use(MasonryWall)

// import waterfall from 'vue-waterfall'
// Vue.use(waterfall)

// el-button 全局防抖 标签加 v-debounce
Vue.directive('debounce', {
    inserted(el, binding) {
      el.addEventListener('click', e => {
        el.classList.add('is-disabled')
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
          el.classList.remove('is-disabled')
        }, 1000)//我这里设置的是2000毫秒也就是2秒
      })
    }
  })

Vue.directive('click-outside', {
    bind(el, binding) {
      // 在元素上绑定一个点击事件监听器
      el.clickOutsideEvent = function(event) {
        // 检查点击事件是否发生在元素的内部
        if (!(el === event.target || el.contains(event.target))) {
          // 如果点击事件发生在元素的外部，则触发指令绑定的方法
          binding.value(event);
        }
      };
      // 在文档上添加点击事件监听器
      document.addEventListener("click", el.clickOutsideEvent);
    },
    unbind(el) {
      // 在元素上解除点击事件监听器
      document.removeEventListener("click", el.clickOutsideEvent);
    }
  })
// const dashboardFlexible = require('lib-flexible-for-dashboard');
// dashboardFlexible.init(16/9)
// import infiniteScroll from "vue-infinite-scroll";
//  Vue.use(infiniteScroll);

Vue.use({axios})

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
    data() {
        return {
            eventHub: new Vue(),
            ws: null,
        }
    }
})