import request from '@/utils/request'

export function getProjectList() {
    return request({
        url: '/api/projects/project/',
        method: 'get'
    })
}

export function getMyTaskList(params) {
    return request({
        url: '/api/projects/mytask/mytasklist/',
        method: 'get',
        params
    })
}

export function updateTask(data) {
    return request({
        url: '/api/projects/task_detail/' + data.id + '/',
        method: 'put',
        data: data
    })
}

export function getOosToken() {
    return request({
        url: '/api/projects/oos_token/',
        method: 'get'
    })
}

export function addTask(data) {
    return request({
        url: '/api/projects/task/',
        method: 'post',
        data
    })
}

export function addNotice(data) {
    return request({
        url: '/api/projects/announcement/',
        method: 'post',
        data
    })
}
//任务提醒列表
export function getNoticeList() {
    return request({
        url: '/api/projects/notice/',
        method: 'get'
    })
}

//任务提醒已读
export function getNoticeRead(data) {
    return request({
        url: '/api/projects/notice/read/',
        method: 'post',
        data
    })
}

export function pushAbnormalNotice(data) {
    return request({
        url: '/api/projects/abnormal/notice/',
        method: 'post',
        data
    })
}

export function getAbnormalTaskList(params) {
    return request({
        url: `/api/projects/mytask/abnormal/`,
        method: 'get',
        params
    })
}
export function rejectChangeTask(data) {
    return request({
        url: '/api/projects/taskchange/disapprove/',
        method: 'post',
        data
    })
}

export function rejectFinishedTask(data) {
    return request({
        url: '/api/projects/taskfinish/disapprove/',
        method: 'post',
        data
    })
}


export function responseAbnormalNotice(data) {
    return request({
        url: '/api/projects/abnormal/process/',
        method: 'post',
        data
    })
}

export function scoreTask(data) {
    return request({
        url: '/api/projects/taskfinish/rate/',
        method: 'post',
        data
    })
}

export function abnormalRead(data) {
    return request({
        url: '/api/projects/abnormal/read/',
        method: 'post',
        data
    })
}

export function getWeatherInfo() {
    return request({
        url: '/green-site/getWeatherInfo/',
        method: 'get'
    })
}

export function getNoticeTaskInfo(data) {
    return request({
        url: '/api/projects/taskchange/change_get/',
        method: 'post',
        data,
    })
}

export function commitChange(data) {
    return request({
        url: '/api/projects/taskchange/change_commit/',
        method: 'post',
        data,
    })
}


export function AddIterativeTask(data) {
    return request({
        url: '/api/projects/taskrepeat/repeat/',
        method: 'post',
        data,
    })
}

export function addReport(data) {
    return request({
        url: '/api/projects/taskreport/',
        method: 'post',
        data,
    })
}

export function getTaskReport(params) {
    return request({
        url: '/api/projects/taskreport/',
        method: 'get',
        params
    })
}

export function taskUploadfile(params) {
    return request({
        url: '/api/projects/task_uploadfile/',
        method: 'get',
        params,
    })
}

export function getTimeEvent(data) {
    return request({
        url: '/api/projects/timeevent-dot/oneday/',
        method: 'post',
        data,
    })
}

export function removeUserFromEvent(data) {
    return request({
        url: '/api/projects/timeevent-dot/exclude/',
        method: 'post',
        data,
    })
}

export function taskOrdering(data){
    return request({
        url: '/api/projects/taskchange/order/',
        method: 'post',
        data,
    })
}

