<template>
  <div class="app-wrapper" :class="classObj">
    <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside"></div>
    <sidebar class="sidebar-container"></sidebar>
    <div :class="{hasTagsView:needTagsView}" class="main-container">
      <!-- <div :class="{'fixed-header':fixedHeader}">
          <navbar></navbar>
          <tags-view v-if="needTagsView"></tags-view>
      </div> -->
      <app-main></app-main>
    </div>
    <div class="right-box">
        <task-reminder :open1.sync="open1"></task-reminder> 
        <notice :open.sync="open2"></notice>
        <calendar :open.sync="open3"></calendar>
    </div>
  </div>
</template>

<script>
import { Navbar, Sidebar, AppMain, TagsView,taskReminder,Notice,calendar} from './components'
import ResizeMixin from './mixin/ResizeHandler'
import { mapState } from 'vuex'

export default {
  name: 'layout',
  data() {
    return {
      open1: true,
      open2: false,
      open3: true,
    }
  },
  components: {
    Navbar,
    Sidebar,
    AppMain,
    TagsView,
    taskReminder,
    Notice,
    calendar
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapState({
      needTagsView: state => state.settings.tagsView,
      fixedHeader: state => state.settings.fixedHeader
    }),
    sidebar() {
      console.log(this.$store.state.app.sidebar)
      return this.$store.state.app.sidebar
    },
    device() {
      return this.$store.state.app.device
    },
    classObj() {
      return {
        // hideSidebar: !this.sidebar.opened, // 隐藏状态栏
        // openSidebar: this.sidebar.opened, 
        // withoutAnimation: this.sidebar.withoutAnimation,
        // mobile: this.device === 'mobile' //移动端处理方案
      }
    }
  },
  created() {
    console.log(this.needTagsView);
    this.getHeight()
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('CloseSideBar', { withoutAnimation: false })
      console.log(this.$store.dispatch)
    },
    getHeight () {
      const winHeight = window.innerHeight
      // this.winHeight = winHeight - tabs 高度 这段可选
      // console.log('winHeight', this.winHeight)
    },
    // variables() {
    //   return variables
    // },
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  @import "src/styles/mixin.scss";
  @import 'src/styles/variables.scss';
  @import 'src/styles/utils.scss';
  .app-wrapper {
    @include clearfix;
    // position: relative;
    height: 100%;
    width: 100%;
    padding: 24px;
    // border: 1px solid #000;
    background: rgba(224, 229, 234, 0.8);
    display: flex;
      &.mobile.openSidebar{
      position: fixed;
      top: 0;
    }
  }
  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
    border: 1px solid #000;
  }
  .right-box{
    // width: 400px;
    width: vw(400);
    // border: 1px  solid #000;
    overflow: hidden;
    border-radius: 24px;
    position: absolute;
    top: 24px;
    right: 24px;
    margin-left: vw(24);
    height: 95.555vh;
    height: calc(var(--vh, 1vh) * 95.555);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // height: calc(100vh - 48px);
  }
  // .scrollMenuBox{
  //   height: 100vh;
  // }
  // .xxx{
  //     height: 258px;
  //     border: 1px solid red;
  // }
  // .yyy{
  //   // width: vw(20);
  //   height: vh(304);
  //   // height: Vh(304);
  //   // height: 28.14%;
  //   // height: 145px;
  //   // height: calc(100vh - 766px);
  //   border: 1px solid red;
  //   margin-top:  vh(16);
  //   background: #FFF;
  //   border-radius: 24px;
  // }
</style>
