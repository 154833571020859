<template>
  <div>
    <div v-if="route.meta" @click="toggleShowChildren" class="title">{{route.meta.title}}</div>
    <div v-show="route.children && show">
      <div v-for="i in route.children" :key="i.id">
        <NewSideBar :route="i" :show-children="false"></NewSideBar>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewSideBar",
  props: {
    route: {
      type: Object,
      default: () => {}
    },
    showChildren: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  data() {
    return {
      show: false
    };
  },
  mounted() {
    this.show = this.showChildren;
  },
  methods: {
    toggleShowChildren() {
      this.show = !this.show;
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  cursor: pointer;
  user-select: none;

  font-size: 14px;
  font-weight: 400;
  height: 40px;
  line-height: 40px;
  padding-left: 24px;
}
</style>