<template>
  <div class="task-reminder" :class="{'remove-m-t': showAllNotice}">
    <div class="conten">
      <div class="top">
        <div class="top_left">
          <div class="top_title">公告</div>
          <span v-if=" count > 0">{{ count }}</span>
        </div>
        <div class="top_right">
          <div class="addNotice" @click.stop="addNoticeOpen">
            <svg-icon icon-class="addNotice"></svg-icon>
            <span>添加</span>
          </div>
          <div class="open" @click.stop="openNotice(false)" v-if="open">
            <img src="../../../../assets/taskReminder_Moer-icon.png" alt />
          </div>
          <div class="open" @click.stop="openNotice(true)" v-else>
            <img src="../../../../assets/notice-top_icon.png" alt />
          </div>
        </div>
      </div>
      <div
        class="infinite-list"
        v-infinite-scroll="load"
        :class="{close:!open,'release-height':showAllNotice}"
        @click="switchAllNotice"
        v-click-outside="()=>{showAllNotice=false}"
      >
        <TransitionGroup name="data">
          <div class="list" v-for="(item,index) in list" :key="item.id">
            <div class="list_title">
              <div class="list_title_left">
                <div class="title">{{ item.title}}</div>
              </div>
              <div v-if="item.can_delete" class="list_title_right">
                <div
                  style="font-size: 14px;color: #f7e6c8;margin-right: 5px;white-space: nowrap"
                >{{item.read_count}} / {{item.users_count}}</div>
                <div class="btn" @click="noitceRecall(item)">撤回</div>
              </div>
              <div v-else class="list_title_right">
                <div
                  style="font-size: 14px;color: #f7e6c8;margin-right: 5px;white-space: nowrap"
                >{{item.read_count}} / {{item.users_count}}</div>
                <div
                  class="btn"
                  @click="noitceRead(item.id)"
                  :style="{background: item.has_read ? '#8a8a8a' : ''}"
                >{{item.has_read ? '已阅' : '了解'}}</div>
              </div>
            </div>
            <div class="content" style="color: #f7e6c8;">
              <span>{{item.content}}</span>
              <displayFileType :Files.sync="item.uploadfiles" @deleteFile="deleteFile" :Type="2"></displayFileType>
              <div class="icon-open" v-if="item.unreads.length > 0" :class="{'icon-close': item.showUnread}" @click="handleUnreads(index)"></div>
            </div>
            <div v-show="item.showUnread" class="unread-title">未读人员</div>
            <div v-show="item.showUnread" class="unreads">
              <div class="user" v-for="i of item.unreads" :key="i.uid">
                <img class="user-headimg" :src="i.headimg" alt />
                <div class="user-name">{{ i.name }}</div>
              </div>
            </div>
          </div>
        </TransitionGroup>
      </div>
    </div>
    <AddNoTice v-if="addNoticeShow" :show.sync="addNoticeShow"></AddNoTice>
    <mask1 v-show="deleteMask" :show.sync="deleteMask">
      <deleteTask @cancel="cancel" @confirm="confirm" :title="title" :name="name"></deleteTask>
    </mask1>
  </div>
</template>

<script>
import { getNoitceList, noitceRead, revampNoitce } from "../../../../api/table";
import AddNoTice from "./addNotice.vue";
import displayFileType from "../../../../components/displayFileType.vue";
import mask1 from "@/components/Task/mask.vue";
import deleteTask from "@/components/Task/deleteTask.vue";
export default {
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AddNoTice,
    displayFileType,
    mask1,
    deleteTask
  },
  data() {
    return {
      list: [],
      count: "",
      addNoticeShow: false,
      deleteMask: false,
      title: "",
      name: "",
      noticeData: "",
      showAllNotice: false
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    this.$root.eventHub.$on("getAfficheList", () => {
      this.getList();
    });
    this.getList();
  },
  methods: {
    handleUnreads(idx){
      console.log(idx);
      if (idx < this.list.length) {
        this.list[idx].showUnread = !this.list[idx].showUnread;
        this.$set(this.list, idx, this.list[idx])
      }
    },
    switchAllNotice() {
      this.showAllNotice = true;
    },
    //打开添加按钮弹框
    addNoticeOpen() {
      this.addNoticeShow = true;
    },
    load() {},
    // 打开公告区域
    openNotice(v) {
      if (!v) {
        this.showAllNotice = false;
      }
      console.log("open....", v);
      this.$emit("update:open", v);
    },
    //获取公告列表
    getList() {
      //获取列表
      getNoitceList()
        .then(res => {
          console.log(res);
          this.count = res.data.count;
          this.list = res.data.results;
          this.$emit("update:open", this.list.length ? true : false);
          // this.activeNames = this.list.length ? true : false
        })
        .catch(err => {});
    },
    //点击已阅（）
    noitceRead(id) {
      console.log(id);
      noitceRead(id)
        .then(res => {
          this.getList();
        })
        .catch(err => {});
    },
    deleteFile() {},
    //关闭删除按钮弹窗
    cancel() {
      console.log("取消按钮");
      this.deleteMask = false;
    },
    //点击二次确认删除
    confirm() {
      console.log("点击撤回...", this.noticeData);
      // console.log(data)
      revampNoitce(this.noticeData)
        .then(res => {
          console.log(res);
          Message({
            message: "撤回成功！",
            type: "success",
            duration: 5 * 1000
          });
          this.getList();
          this.deleteMask = false;
        })
        .catch(err => {});
      this.deleteMask = false;
    },
    //点击撤回
    noitceRecall(item) {
      this.noticeData = {
        id: item.id,
        isactive: 0
      };
      this.deleteMask = true;
      this.name = item.title;
      this.title = "这条公告给撤回吗";
    }
  }
};
</script>

<style lang="scss" scoped>
.data-move, /* 对移动中的元素应用的过渡 */
    .data-enter-active,
    .data-leave-active {
  transition: all 0.5s ease;
}

.data-enter-from,
.data-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* 确保将离开的元素从布局流中删除
    以便能够正确地计算移动的动画。 */
.data-leave-active {
  position: absolute;
}

.unread-title {
  margin: 12px 0;
  color: #fff;
  font-size: 12px;
  // border-top: 0.00521rem solid #7d7d7d;
  padding-top: 12px;
}

.unreads {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 12px;
  .user {
    margin-right: 8px;
    margin-bottom: 8px;
    min-width: vw(38);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &-headimg {
      width: 20px;
      height: 20px;
      margin-bottom: 6px;
      border-radius: 50%;
    }
    &-name {
      font-size: 12px;
      color: #fff;
    }
  }
}
.task-reminder {
  margin-top: vh(16);
  background: #fff;
  border-radius: 24px;
  box-shadow: 0px 0px 12px 0px #e8f0fb;
  transition: all 0.5s ease-in-out;
  .conten {
    height: calc(100% - 24px);
    // border: 1px solid #000;
    padding: vh(24) vh(24);
    overflow: hidden;
    
    .top {
      font-size: vh(18);
      color: #103268;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 5px;
      &_left {
        display: flex;
        align-items: center;
        span {
          font-size: 10px;
          width: vh(16);
          height: vh(16);
          border-radius: 100%;
          background: #3787ff;
          text-align: center;
          color: #fff;
          margin-left: vh(8);
        }
      }
      &_right {
        display: flex;
        align-items: center;
        justify-content: center;
        .addNotice {
          color: #0d68fd;
          font-size: 10px;
          padding: 5px 10px;
          background: linear-gradient(
            90deg,
            rgba(73, 167, 255, 0.1) 0%,
            rgba(71, 119, 255, 0.1) 100%
          );
          border-radius: 16px 16px 16px 16px;
          margin-right: 24px;
          span {
            margin-left: 4px;
          }
        }
        .open {
          width: 24px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            transform: scale(0.66);
            // border: 1px solid red;
          }
        }
      }
    }
    .list {
      border-radius: 12px;
      // background: #F4F9FF;
      background: #3b352c;
      padding: vh(16);
      margin-top: vh(8);

      &_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-items: flex-start;
        border-bottom: 1px solid #7d7d7d;
        padding-bottom: 5px;
        &_left {
          font-size: vh(16);
          // color: #103268;
          color: #faedd6;
          font-family: 800;
          .text {
            margin-top: vh(8);
            font-size: vh(13);
            // border: 1px solid #000;
            // font-weight: 4;
          }
        }
        &_right {
          color: #000;
          font-size: vh(10);
          text-align: center;
          display: flex;
          align-items: center;
          .btn {
            @include cursorPointer;
            width: vw(48);
            height: vh(24);
            line-height: vh(24);
            border-radius: 50px;
            background: linear-gradient(56deg, #e2af2c 16.47%, #da962c 83.18%);
          }
        }
      }
      .content {
        margin-top: 5px;
        font-weight: 700;
        font-size: 12px;
        position: relative;
        .icon-close{
          transform: rotate(180deg) translateY(-4px);
        }
        .icon-open {
          width: 22px;
          height: 22px;
          // background: red;
          position: absolute;
          right: 0px;
          bottom: -4px;
          display: flex;
          align-items: center;
         justify-content: center;
         @include cursorPointer;
         transition: all 0.3s ease-in-out;

          &::after{
            content: '';
            display: block;
            width: 9px;
            height: 9px;
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff;
            transform: rotate(45deg);
          }
        }
        span {
          padding-bottom: 10px;
        }
      }
    }
    .list:nth-child(1) {
      margin-top: vh(16);
      // border: 1px solid #000;
    }
  }
}
.release-height {
  max-height: vh(1000) !important;
}
.remove-m-t {
  margin-top: 0px !important;
}
.infinite-list {
  transition: all 0.3s;
  padding-bottom: 20px;
  overflow: auto;
  max-height: vh(180);
}
.infinite-list::-webkit-scrollbar {
  width: 0;
}
.close {
  max-height: 0px;
  padding-bottom: 0px;
}

/deep/.filetype {
  padding: 0;
}
/deep/ .file {
  margin-top: 15px;
  width: 0;
  .inner2 {
    img {
      width: 36px;
      height: 36px;
      border-radius: 4px;
    }
    .fileName {
      display: none;
    }
  }
}
</style>