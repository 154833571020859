<template>
  <div class="persons">
    <div
      class="users-box"
      v-if="showUserList && isAll"
      ref="usersBox"
      v-click-outside="()=>{showUserList = false}"
    >
      <ChooseUser :show.sync="showUserList" @addNewUsers="addNewUsers"></ChooseUser>
    </div>
    <userListModalBox
      v-if="!isAll"
      :mask="false"
      :Top="30"
      v-show="showUserList"
      :show.sync="showUserList"
      :List="userlist"
      @addUsers="addNewUsers"
    ></userListModalBox>
    <div
      class="person"
      v-for="(item, index) of userlist"
      :key="item.uid"
      @click="delManager(index)"
    >
      <div class="inner">
        <img class="icon" :src="item.headimg" />
        {{ item.name }}
      </div>
    </div>
    <div class="add-user" v-if="userlist.length < limit" @click="clickAddUser">+</div>
  </div>
</template>

<script>
import userListModalBox from "@/components/User/userListModalBox.vue";
import ChooseUser from "@/components/architeture/chooseUser.vue";
export default {
  components: { userListModalBox, ChooseUser },
  props: {
    userlist: {
      type: Array,
      default: () => []
    },
    limit: {
      type: Number,
      default: 99999
    },
    isAll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showUserList: false
    };
  },
  methods: {
    clickAddUser(e) {
      this.$root.eventHub.$emit("close-choose-user", null);
      this.showUserList = true;
      setTimeout(() => {
        const usersBox = this.$refs.usersBox;
        if (usersBox) {
          usersBox.style.left = e.clientX + 20 + "px";
          usersBox.style.top = e.clientY - 10 + "px";
        }
      }, 200);
    },
    delManager(idx) {
      this.userlist.splice(idx, 1);
    },
    addNewUsers(users) {
      let arr = [...this.userlist, ...users].map(item => {
        return JSON.stringify({
          uid: item.uid ?? item.id,
          headimg: item.headimg,
          name: item.name
        });
      });
      console.log("arr...", arr);
      arr = [...new Set(arr)];
      this.$emit(
        "update:userlist",
        arr.map(item => JSON.parse(item))
      );
      // console.log("users...",  [...this.userlist, ...users]);
    },
    addEventListener() {
      setTimeout(() => {
        let els = document.querySelectorAll(".inner");
        console.log("els", els.length);
        els.forEach(item => {
          item.addEventListener("click", e => {
            e.stopPropagation();
            return false;
          });
        });
      }, 100);
    }
  },
  watch: {
    userlist: {
      handler(newVal, oldVal) {
        this.addEventListener();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.users-box {
  width: 300px;
  max-height: 500px;
  position: fixed;
  left: -400px;
  top: 0;
  z-index: 999;
}
.persons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: -4px;
  min-height: 36px;

  .person {
    height: 36px;
    line-height: 36px;
    padding: 2px 16px 2px 40px;
    color: #103268;
    font-size: 14px;
    position: relative;
    border-radius: 18px;
    background: #f4f9ff;
    margin-right: 16px;
    user-select: none;
    position: relative;
    margin-bottom: 4px;
    .icon {
      width: 32px;
      height: 32px;
      border-radius: 16px;
      position: absolute;
      left: 2px;
      top: 2px;
    }
  }
  .add-user {
    width: 24px;
    height: 24px;
    line-height: 22px;
    border-radius: 12px;
    border: 1px dashed rgba(24, 110, 255, 0.2);
    text-align: center;
    font-size: 20px;
    font-weight: 100;
    color: #3989ff;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
  }
  .person:hover::after {
    content: "+";
    width: 14px;
    height: 14px;
    line-height: 15px;
    display: block;
    position: absolute;
    text-align: center;
    border-radius: 7px;
    font-size: 12px;
    transform: rotate(45deg);
    top: 0;
    right: -4px;
    color: #fff;
    background: #103268;
    opacity: 0.4;
    cursor: pointer;
  }
}
</style>