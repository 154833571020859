<template>
  <div class="container" v-loading="loading" element-loading-text="文件上传中">
    <Report v-if="showReprot" :show.sync="showReprot" :task="task.id" @reportSuc="reportSuc"></Report>
    <div class="title">编辑任务详情</div>
    <el-form class="form" label-width="60px">
      <el-form-item label="任务名称">
        <el-input class="input" v-model="task.name"></el-input>
      </el-form-item>
      <el-form-item label="负责人">
        <ChoosePerson :userlist.sync="task.taskuser_list" :limit="1"></ChoosePerson>
      </el-form-item>
      <el-form-item label="校对人">
        <ChoosePerson :userlist.sync="task.review" :limit="1"></ChoosePerson>
      </el-form-item>
      <el-form-item label="开始时间">
        <el-date-picker
          :class="{'start-time-changed': task.startTimeChanged, 'end-time-changed': task.endTimeChanged}"
          v-model="task.times"
          type="daterange"
          range-separator="结束时间"
          start-placeholder="请选择"
          end-placeholder="请选择"
          value-format="yyyy-MM-dd"
          :unlink-panels="true"
          @change="timesChanged"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="修改原因" v-if="showReason">
        <el-input
          class="input"
          type="textarea"
          :rows="2"
          placeholder="请输入修改的原因"
          v-model="task.delay_reason"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="进度">
        <el-input class="input progress" v-model="task.pct2" type="number"></el-input>
        <div class="per">%</div>
      </el-form-item> -->
      <el-form-item label="附件">
        <UploadTool
          :files.sync="task.uploadfiles"
          :deleted-files.sync="upload.deletedFiles"
          :new-files.sync="upload.newFiles"
          :drag="false"
        ></UploadTool>
      </el-form-item>
      <div class="bottom">
        <div class="cancel" @click="cancel">取消</div>
        <el-button class="submit" @click="submit" v-debounce>确定</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { updateTask } from "@/api/task";
import { Message } from "element-ui";
import ChoosePerson from "@/components/Task/choosePerson.vue";
import UploadTool from "@/components/Task/uploadTool.vue";
import Report from "@/components/Task/report.vue";

export default {
  name: "edit",
  components: { ChoosePerson, UploadTool, Report },
  props: {
    task: {
      type: Object,
      default: () => {}
    },
    start_time: {
      type: String,
      default: ""
    },
    end_time: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      rules: {
        title: [{ required: true, message: "请输入任务名称", trigger: "blur" }]
      },
      loading: false,
      upload: {
        oosToken: {},
        deletedFiles: [],
        newFiles: []
      },
      showReprot: false
    };
  },
  mounted() {},
  computed: {
    showReason() {
      return this.task.startTimeChanged || this.task.endTimeChanged;
    },
    pickerOptions() {
      let _this = this;
      return {
        disabledDate(time) {
          const start_time = _this.task.plan_start_time + " 00:00:00";
          const end_time = _this.task.plan_end_time;
          console.log(
            start_time,
            end_time,
            "选择时间选择时间选择时间选择时间选择时间选择时间选择时间选择时间"
          );
          // return time.getTime() < new Date(start_time).getTime() || time.getTime() > new Date(end_time).getTime()
          // return time.getTime() < new Date(start_time).getTime()
          // return new Date(start_time).getTime() >  time.getTime()
        }
      };
    }
  },
  mounted() {
    // console.log(this.task,'选择时间选择时间选择时间选择时间选择时间选择时间选择时间选择时间');
  },
  methods: {
    closeMask() {
      this.$emit("update:show", false);
    },
    timesChanged(data) {
      this.task.startTimeChanged = this.task.plan_start_time !== data[0];
      this.task.endTimeChanged = this.task.plan_end_time !== data[1];
    },
    reportSuc() {
      this.submitTask();
      this.showReprot = false;
    },
    submit() {
      if (this.task.pct2 == 100) {
        this.showReprot = true;
        return;
      }
      this.submitTask()
    },
    submitTask() {
      const data = {
        id: this.task.id,
        name: this.task.name,
        pct: this.task.pct2 * 0.01,
        uf_deleted: this.upload.deletedFiles,
        taskuser: this.task.taskuser_list.map(e => e.uid),
        review: this.task.review.map(e => e.uid),
        plan_start_time: this.task.times[0],
        plan_end_time: this.task.times[1],
        delay_reason: this.task.delay_reason
      };
      if (this.showReason && !this.task.delay_reason) {
        Message.error("请填写修改原因");
        return
      }
      if (this.upload.newFiles.length > 0) {
        data.uploadfiles = this.upload.newFiles.map(e => {
          return {
            local_key: e.local_key,
            origin_name: e.origin_name
          };
        });
        updateTask(data)
          .then(res => {
            Message.success(res.msg);
            this.$emit("dataChanged", res.data);
            this.closeMask();
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {});
      } else {
        updateTask(data)
          .then(res => {
            Message.success(res.msg);
            this.$emit("dataChanged", res.data);
            this.closeMask();
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {});
      }
    },
    cancel() {
      this.closeMask();
    }
  },
  watch: {
    "task.pct2": {
      handler(newVal, oldVal) {
        if (newVal && oldVal && newVal == 100) {
          this.showReprot = true;
        }
      },
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .start-time-changed .el-range-input:nth-of-type(1) {
  color: #0d68fd;
}

/deep/ .end-time-changed .el-range-input:nth-of-type(2) {
  color: #0d68fd;
}

.edit-box::-webkit-scrollbar {
  display: none;
}
.container {
  .per {
    position: absolute;
    left: 80px;
    top: 0;

    color: #103268;
    font-size: 14px;
    font-style: normal;
    opacity: 0.4;
  }
  .progress {
    width: 100px;
  }
}
</style>