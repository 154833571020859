<template>
  <div class="taskEvent" @click.stop="null">
    <div id="getEvent" class="getEvent">
      <div class="users-box">
        <div class="user-box" v-for="item of eventList.users_in_group" :key="item.uid">
          <img class="user" :src="item.headimg" :title="item.name" alt />
          <div class="del" @click="removeUser(item)">+</div>
        </div>
      </div>
      <div class="list">
        <div class="Eventlist_top">
          <div class="top_left">
            <el-avatar :size="16" :src="eventList.headimg"></el-avatar>
            <span>{{ eventList.username }}</span>
          </div>
          <div class="top_right">
            <span>{{ eventList.create_time }}</span>
          </div>
        </div>
        <div class="explain">{{ eventList.content }}</div>
        <FileType v-if="eventList.uploadfiles" :Files.sync="eventList.uploadfiles" :Type="2"></FileType>
      </div>
      <div class="list" v-for="item in eventList.data" :key="item.id" :class="{self: myUid == item.user}">
        <div class="Eventlist_top">
          <div class="top_left">
            <el-avatar :size="16" :src="item.headimg"></el-avatar>
            <span>{{ item.username }}</span>
          </div>
          <div class="top_right">
            <span>{{ item.create_time }}</span>
          </div>
        </div>
        <div class="explain">{{ item.content }}</div>
        <FileType v-if="item.uploadfiles.length > 0" :Files.sync="item.uploadfiles" :Type="2"></FileType>
      </div>
      <div class="reply-box" v-if="showReply">
        <Comment @addComment="addComment" :bgColor="'#f4f9ff'" :autoat="false"></Comment>
      </div>
    </div>
  </div>
</template>

<script>
import { getTimeEvent, removeUserFromEvent } from "@/api/task";
import FileType from "@/components/displayFileType.vue";
import Comment from "@/components/User/comment.vue";
import { setTimeEvent } from "@/api/gantt";
import { getUid } from "@/utils/auth";
import { Message } from "element-ui";

export default {
  components: {
    Comment,
    FileType
  },
  props: {
    showReply: {
      type: Boolean,
      default: false
    },
    eventid: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      myUid: getUid(),
      eventList: {}
    };
  },
  methods: {
    removeUser(user) {
      if (confirm(`是否从对话中移除 ${user.name} ?`)) {
        removeUserFromEvent({
          id: this.eventid,
          uid: user.uid
        })
          .then(res => {
            Message.success("移除成功");
            this.getTimeEvent();
          })
          .catch(err => {});
      } else {
        this.$message({
          type: "info",
          message: "已取消移除"
        });
      }

      //   this.$confirm("是否从对话中移除?", "提示", {
      //     confirmButtonText: "确定",
      //     cancelButtonText: "取消",
      //     type: "warning"
      //   })
      //     .then(() => {
      //       removeUserFromEvent({
      //         id: this.eventid,
      //         uid: uid
      //       })
      //         .then(res => {
      //           Message.success("移除成功");
      //           this.getTimeEvent();
      //         })
      //         .catch(err => {});
      //     })
      //     .catch(() => {
      //       this.$message({
      //         type: "info",
      //         message: "已取消移除"
      //       });
      //     });
    },
    addComment(data) {
      data.user = getUid();
      data.pid = this.eventid;
      setTimeEvent(data)
        .then(res => {
          Message({
            message: "评论成功",
            type: "success",
            duration: 3 * 1000
          });
          this.getTimeEvent();
        })
        .catch(err => {
          console.log(err);
        });
    },
    getTimeEvent() {
      if (this.eventid) {
        getTimeEvent({
          id: this.eventid
        })
          .then(res => {
            // console.log(res);
            this.eventList = res.data;
            setTimeout(() => {
              let box = document.querySelector(".getEvent");
              if (box) {
                box.scrollTop = box.scrollHeight;
              }
            }, 300);
          })
          .catch(err => {});
      }
    }
  },
  mounted() {
    this.getTimeEvent();
  },
  watch: {
    eventid(val) {
      if (val) {
        this.getTimeEvent();
      }
    }
  }
};
</script>

<style lang="scss">
.taskEvent {
  width: 350px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 4px 25px 0px rgba(17, 22, 28, 0.2);
  padding: 0px 12px 16px 12px;
  overflow: hidden;

  .self{
    background: lightgreen!important;
  }

  .users-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 12px 0 0 0;

    position: sticky;
    top: 0;
    background: #fff;
    z-index: 2;
    box-shadow: 0 0 10px 0 #e8f0fb;

    .user-box {
      position: relative;
      .user {
        margin-right: 6px;
        margin-bottom: 6px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }

      .del {
        width: 16px;
        height: 16px;
        border-radius: 8px;
        color: #fff;
        background: rgba($color: #000000, $alpha: 0.5);
        display: none;
        justify-content: center;
        align-items: center;
        transform: rotate(45deg);
        font-size: 15px;
        position: absolute;
        top: 0px;
        right: 4px;
        cursor: pointer;
        user-select: none;
      }
    }
    .user-box:hover .del {
      display: flex;
    }
  }

  .reply-box {
    padding-top: 12px;
    position: sticky;
    bottom: 0;
    background: #fff;
    z-index: 2;
  }

  .getEvent {
    color: #103268;
    font-size: 10px;
    max-height: 94vh;
    max-height: calc(var(--vh, 1vh) * 94);
    overflow: auto;
    scroll-behavior: smooth;
    .list {
      padding-bottom: 12px;
      border-bottom: 1px solid rgba(24, 110, 255, 0.05);
      box-shadow: -8px 8px 15px transparent;

      .Eventlist_top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .top_left {
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            margin-left: 4px;
          }
        }

        .top_right_edit {
          display: flex;
          color: #0d68fd;
          justify-content: space-between;
          @include cursorPointer;

          span {
            margin: 0 3px;
          }
        }
      }

      .explain {
        margin-top: 8px;
        font-size: 12px;
        margin-left: 4px;
        width: 100%;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-all;
        white-space: normal;
      }

      span {
        display: block;
      }

      .uploadfiles {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 16px;

        .pic {
          width: 48px;
          height: 48px;
          margin: 4px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .list {
    margin-top: vh(8);
    padding: vh(16);
    background: #f4f9ff;
    border-radius: 12px;
    font-size: vh(12);
    position: relative;
    cursor: pointer;
  }
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #e0e0e0;
}
</style>