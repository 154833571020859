import router from '@/route/index'
import { fnAddDynamicMenuRoutes } from './getAsyncRouter'
import { mymenu } from '@/api/auth'
import { getDynamicRoutes, setDynamicRoutes, getToken } from '@/utils/auth'
let hasSetDynamicRouter = false; // 是否已经添加动态路由
// 导航守卫
router.beforeEach((to, from, next) => {
    // 判断该路由是否需要登录权限
    if (getToken()) {
        if (!getDynamicRoutes()) {
            router.options.routes = []
            mymenu().then(res => {
                setDynamicRoutes(res.data);
                let menuRoutes = fnAddDynamicMenuRoutes(res.data);
                menuRoutes.forEach(item => {
                    router.addRoute(item);
                    router.options.routes.push(item);
                })
                hasSetDynamicRouter = true;
                myNext(to, next, true)
            }).catch(err => {

            })
        } else {
            if (!hasSetDynamicRouter) {
                let menuRoutes = fnAddDynamicMenuRoutes(getDynamicRoutes())
                menuRoutes.forEach(item => {
                    router.addRoute(item);
                    router.options.routes.push(item);
                })
                hasSetDynamicRouter = true;
                console.log('hasSetDynamicRouter', menuRoutes);
                myNext(to, next, true)

            } else {
                myNext(to, next)
            }
        }
    } else {
        // 未登录,跳转到登陆页面
        if (to.path !== '/login') {
            console.log('未登录,跳转到登陆页面');
            if (to.path == '/login-admin') {
                next()
            } else {
                next({
                    path: '/login'
                })
            }
        } else {
            next()
        }
    }
})

function myNext(to,next,replace){
    console.log('to....',to);
    if (to.path == '/login') {
        next({
            path: '/index'
        })
    } else {
        if (!replace) {
            next()
        } else {
            next({ ...to, replace: true })
        }
    }
}

router.afterEach(() => {
    // 在即将进入新的页面组件前，关闭掉进度条
})