<template>
  <!-- task-reminder  :class="[windowHeight >=defaultWindowHeight ? '' : 'task-reminder_min']"-->
  <div class="task-reminder" :class="{grow: open1}">
    <EditMask v-if="mask.show" :show.sync="mask.show">
      <RejectTask
        :form="mask.data"
        :show.sync="mask.show"
        @refreshNotices="getNoticeList"
        :type="rejectType"
      ></RejectTask>
    </EditMask>

    <EditMask v-if="mask.showEdit" :show.sync="mask.showEdit">
      <UpdateTask :task="mask.data" :show.sync="mask.showEdit" @dataChanged="dataChanged"></UpdateTask>
    </EditMask>

    <EditMask v-if="mask.showScore" :show.sync="mask.showScore" :width="800">
      <ScoreTask
        :noticeid="mask.scoreData.id"
        :task="mask.scoreData.taskid"
        :content="mask.scoreData.content"
        :show.sync="mask.showScore"
        @refreshNotices="getNoticeList"
      ></ScoreTask>
    </EditMask>
    <div class="conten">
      <div class="top">
        <div class="top_title">任务提醒</div>
        <span v-if="count >0 ">{{ count }}</span>
      </div>
      <div
        class="event-box"
        :class="{'show-event-box': showEventBox}"
        v-click-outside="() => {showEventBox = false}"
      >
        <EventList v-if="showEventBox" :eventid="eventid"></EventList>
      </div>
      <div class="infinite-list" v-infinite-scroll="load" v-if="open1">
        <TransitionGroup name="list">
          <div v-for="(item) in noticeList" :key="item.id">
            <div
              class="list no-shadow"
              v-if="item.t == NOTICE_TYPE.BASE"
              @click="toProjectList(item)"
            >
              <div class="list_item list_item-title title_max_w">
                <div>{{item.projectname ? item.projectname : item.content.projectname}}</div>
              </div>
              <div class="list_item" v-if="item.content.taskname">
                <div class="state">
                  <span>任务名称：</span>
                </div>
                <div>{{ item.content.taskname}}</div>
              </div>
              <div class="list_item" v-if="item.taskusers">
                <div class="state">
                  <span>负责人：</span>
                </div>
                <div>{{ item.taskusers}}</div>
              </div>
              <div class="list_item" v-if="item.content.plan_time">
                <div class="state">
                  <span>时间：</span>
                </div>
                <div>{{ item.content.plan_time}}</div>
              </div>
              <div class="list_item">
                <div class="state">
                  <span>详情：</span>
                </div>
                <div class="detail-info">{{item.content.comment}}</div>
              </div>
              <div class="list_item" v-if="item.content.reason">
                <div class="state">
                  <span>驳回原因：</span>
                </div>
                <div>{{item.content.reason}}</div>
              </div>
              <div class="list_item" v-if="item.content.pause_reason">
                <div class="state">
                  <span>原因：</span>
                </div>
                <div>{{item.content.pause_reason}}</div>
              </div>
              <div class="list_read">
                <img src="../../../../assets/normalTask_icon.png" alt />
              </div>
              <div class="btn_known" @click.stop="getNoticeRead(item)">已了解</div>
            </div>
            <div
              class="list"
              v-if="item.t == NOTICE_TYPE.TASK_FINISH"
              @click="toProjectList(item)"
            >
              <div class="list_item list_item-title accomplishTask">
                <div class="state">
                  <span>任务完成</span>
                </div>
                <div>{{item.projectname}}</div>
              </div>
              <div class="list_item">
                <div class="state">
                  <span>任务名称：</span>
                </div>
                <div>{{item.taskname}}</div>
              </div>
              <div v-if="item.content && item.content.taskstatus" class="list_item">
                <div class="state">
                  <span>任务状态：</span>
                </div>
                <div>{{item.content.taskstatus}}</div>
              </div>
              <div class="list_item">
                <div class="state">
                  <span>负责人：</span>
                </div>
                <div>{{item.content.taskusers}}</div>
                <div
                  v-if="item.content.taskusers != item.content.sender"
                >（填报人:{{ item.content.sender}}）</div>
              </div>
              <div class="list_bottom accomplishBtn">
                <div class="btn" @click.stop="scoreTask(item)">评分</div>
                <div class="btn" @click.stop="rejectCompletedTask(item)">驳回</div>
              </div>
            </div>
            <div
              class="list"
              v-if="item.t == NOTICE_TYPE.TASK_CHANGE"
              @click="toProjectList(item)"
            >
              <div class="list_item list_item-title">
                <div class="state">
                  <span>申请变更</span>
                </div>
                <div>{{item.projectname}}</div>
              </div>
              <div class="list_item">
                <div class="state">
                  <span>变更模块：</span>
                </div>
                <div>{{item.taskname}}</div>
              </div>
              <div class="list_item" v-if="item.content.plan_time&&item.content.origin_time">
                <div class="state">
                  <span>变更时间：</span>
                </div>
                <div class="time">
                  <span>{{item.content.plan_time}}</span>
                  （原计划时间：{{item.content.origin_time}}）
                </div>
              </div>
              <div class="list_item" v-if="item.content.new_taskusers&&item.content.taskusers">
                <div class="state">
                  <span>变更负责人：</span>
                </div>
                <div class="time">
                  <span>{{item.content.new_taskusers}}</span>
                  （原负责人：{{item.content.taskusers}}）
                </div>
              </div>
              <div class="list_item">
                <div class="state">
                  <span>变更原因：</span>
                </div>
                <div>{{item.content.change_reason}}</div>
              </div>
              <div class="list_item">
                <div class="state">
                  <span>申请人员：</span>
                </div>
                <div>{{item.content.taskusers}}</div>
                <div
                  v-if="item.content.taskusers != item.content.sender"
                >（填报人:{{ item.content.sender}}）</div>
              </div>
              <div class="list_item" v-if="item.content.results">
                <div class="state">
                  <span>审批人员：</span>
                </div>
                <div>{{item.agrees}}</div>
              </div>
              <div class="list_bottom">
                <div class="btn" @click.stop="postApprove(item.id)">同意</div>
                <div class="btn" @click.stop="clickEdit(item)">修改</div>
                <div class="btn" @click.stop="clickReject(item)">驳回</div>
              </div>
            </div>
            <div
              class="list"
              v-if="item.t == NOTICE_TYPE.TASK_ABNORMAL"
              @click="toProjectList(item)"
            >
              <div class="list_item list_item-title abnormityTask">
                <div class="state">
                  <span>异常处理</span>
                </div>
                <div>{{item.projectname}}</div>
              </div>
              <div class="list_item">
                <div class="state">
                  <span>异常模块：</span>
                </div>
                <div>{{item.taskname}}</div>
              </div>
              <div class="list_item">
                <div class="state">
                  <span>负责人：</span>
                </div>
                <div>{{item.taskusers}}</div>
              </div>
              <div class="list_item">
                <div class="state">
                  <span>发送人：</span>
                </div>
                <div>{{item.content.sender}}</div>
              </div>
              <div class="list_bottom abnormityhBtn">
                <div class="btn" @click.stop="responseAbnormalTask({id:item.id,status:'正在处理'})">正在处理</div>
                <div class="btn" @click.stop="responseAbnormalTask({id:item.id,status:'已解决'})">已解决</div>
              </div>
            </div>
            <div class="list2" v-if="item.t == NOTICE_TYPE.TASK_EVENT">
              <div
                class="list_item list_item-title pointer title_max_w"
                @click="toProjectList(item)"
              >
                <div>{{item.projectname}}</div>
              </div>
              <div class="list_item pointer" @click="toProjectList(item)">
                <div class="state">
                  <span>任务名称：</span>
                </div>
                <div>{{item.taskname}}</div>
              </div>
              <div
                class="list_item pointer"
                v-if="item.event"
                @click="toProjectList(item)"
              >
                <div class="state">
                  <span>事件名称：</span>
                </div>
                <div>{{item.event.title}}</div>
              </div>
              <div class="list_item pointer" @click="toProjectList(item)">
                <span class="state">提醒详情：</span>
                {{item.content.content}}
              </div>
              <div class="list_item pointer" @click="toProjectList(item)">
                <span class="state">发送人员：</span>
                {{item.content.sender}}
              </div>
              <div class="list_item" v-if="item.event && item.event.uploadfiles.length > 0">
                <div class="file-list">
                  <span class="state">文件列表：</span>
                  <UploadTool :files.sync="item.event.uploadfiles" :drag="false" :readonly="true"></UploadTool>
                </div>
              </div>
              <div class="list_read">
                <img src="../../../../assets/normalTask_icon.png" alt />
                <div class="unread" v-if="item.event && item.event.unread > 1">{{item.event.unread}}</div>
              </div>
              <div class="list_bottom">
                <Comment
                  :d="{...item.event, 'id': item.id}"
                  @addComment="addComment"
                  :show.sync="showEventBox"
                  :eventid.sync="eventid"
                ></Comment>
                <div class="btn" @click.stop="getNoticeRead(item)">已了解</div>
              </div>
            </div>

            <div
              class="list"
              v-if="item.t == NOTICE_TYPE.TASK_CREATE"
              @click="toProjectList(item)"
            >
              <div class="list_item list_item-title">
                <div class="state">
                  <span>任务创建</span>
                </div>
                <div>{{item.projectname}}</div>
              </div>
              <div class="list_item">
                <div class="state">
                  <span>任务名称：</span>
                </div>
                <div>{{item.taskname}}</div>
              </div>
              <div class="list_item">
                <div class="state">
                  <span>任务时间：</span>
                </div>
                <div class="time">
                  <span>{{item.content.plan_time}}</span>
                </div>
              </div>
              <div class="list_item">
                <div class="state">
                  <span>负责人：</span>
                </div>
                <div>{{item.content.taskusers}}</div>
                <div
                  v-if="item.content.taskusers != item.content.sender"
                >（填报人:{{ item.content.sender}}）</div>
              </div>
              <div class="list_bottom">
                <div class="btn" @click.stop="postApprove(item.id)">同意</div>
                <div class="btn" @click.stop="clickEdit(item)">修改</div>
                <div class="btn" @click.stop="clickReject(item,'createTask')">驳回</div>
              </div>
            </div>

            <div
              class="list no-shadow"
              v-if="item.t == NOTICE_TYPE.NOTE_SHARE"
              @click="toNotePage()"
            >
              <div class="list_item list_item-title title_max_w">
                <div>{{item.content.title}}</div>
              </div>
              <div class="list_item">
                <div class="state">
                  <span>详情：</span>
                </div>
                <div>{{item.content.comment}}</div>
              </div>
              <div class="list_item">
                <div class="state">
                  <span>分享人：</span>
                </div>
                <div>{{ item.content.sender}}</div>
              </div>
              <div class="list_read">
                <img src="../../../../assets/normalTask_icon.png" alt />
              </div>
              <div class="btn_known" @click.stop="getNoticeRead(item)">已了解</div>
            </div>

            <div
              class="list"
              v-if="item.t == NOTICE_TYPE.BASE_OPTIONS"
              @click="toProjectList(item)"
            >
              <div class="list_item list_item-title">
                <div>{{item.projectname}}</div>
              </div>
              <div v-for="(it,idx) of item.content.comment" :key="idx" class="list_item">
                <div class="state">
                  <span>{{it.title}}：</span>
                </div>
                <div>{{it.value}}</div>
              </div>
              <div class="list_bottom accomplishBtn">
                <div class="btn" @click.stop="noticeApprove(item.id)">同意</div>
                <div class="btn" @click.stop="noticeRefuse(item.id)">驳回</div>
              </div>
            </div>
          </div>
        </TransitionGroup>
      </div>
    </div>
    <!-- <div class="collapse">
      <div class="open" @click="activeOpen" v-if="!open1">
        <img src="../../../../assets/taskReminder_Moer-icon.png" alt />
      </div>
      <div class="open close" @click="activeOpen" v-else>
        <img src="../../../../assets/notice-top_icon.png" alt />
      </div>
    </div>-->
  </div>
</template>

<script>
import {
  getNoticeList,
  getNoticeRead,
  responseAbnormalNotice,
  getNoticeTaskInfo
} from "../../../../api/task";
import {
  postApprove,
  noticeApprove,
  noticeRefuse
} from "../../../../api/table";
import { setTimeEvent } from "@/api/gantt";
import { Message } from "element-ui";
import { getUid } from "@/utils/auth";
import EditMask from "@/components/Task/mask.vue";
import RejectTask from "@/components/Task/rejectTask.vue";
import ScoreTask from "@/components/Task/scoreTask.vue";
import UpdateTask from "@/components/Task/updateTask.vue";
import UploadTool from "@/components/Task/uploadTool.vue";
import Comment from "@/components/User/comment.vue";
import EventList from "@/components/Task/eventList.vue";
export default {
  components: {
    EditMask,
    RejectTask,
    ScoreTask,
    UpdateTask,
    UploadTool,
    Comment,
    EventList
  },
  props: {
    open1: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      windowWidth: 0, //窗口宽度
      windowHeight: 0, //窗口高度
      defaultWindowHeight: 1080, //默认窗口高度
      render: true,
      noticeList: [],
      count: 0,
      showEventBox: false,
      mask: {
        show: false,
        showScore: false,
        data: {},
        scoreData: "",
        showEdit: false,
        noticeid: ""
      },
      messageData: {},
      height1: "height1",
      height2: "height2",
      height3: "height3",
      height4: "height4",
      rejectType: "",
      NOTICE_TYPE: {
        BASE: 0,
        TASK_FINISH: 1,
        TASK_CHANGE: 2,
        TASK_ABNORMAL: 3,
        TASK_EVENT: 4,
        TASK_CREATE: 5,
        NOTE_SHARE: 6,
        BASE_OPTIONS: 9
      },
      eventid: null
    };
  },
  computed: {},
  mounted() {
    this.getNoticeList();
    this.$root.eventHub.$on("getNoticeList", () => {
      this.getNoticeList();
    });
  },
  methods: {
    addComment(data) {
      console.log("data...", data);
      data.user = getUid();
      setTimeEvent(data)
        .then(res => {
          Message({
            message: "评论成功",
            type: "success",
            duration: 3 * 1000
          });
          this.getNoticeRead(data);
        })
        .catch(err => {
          console.log(err);
        });
    },
    toProjectList(obj) {
      const { taskid } = obj;
      const id = obj.projectid ? obj.projectid : obj.content.projectid;
      const path = taskid
        ? `/projectList?id=${id}&taskid=${taskid}`
        : `/projectList?id=${id}`;
      const { href } = this.$router.resolve({
        path
      });
      window.open(href, "_blank");
    },
    toNotePage(id) {
      if (this.$route.path == "/itemRecord/Outgoing_business") {
      } else {
        this.$router.push({
          path: `/itemRecord/Outgoing_business?share=1`
        });
      }
    },
    //获取窗口宽高
    // getWindowSize() {
    //     this.windowWidth = window.innerWidth;
    //     this.windowHeight = window.innerHeight;
    //     if(this.windowHeight >=1080){
    //         console.log(this.windowHeight)
    //         console.log('3333')
    //     }else{
    //         console.log('444')
    //     }
    // },
    //获取任务提醒列表
    getNoticeList() {
      getNoticeList()
        .then(res => {
          // console.log(res);
          let notices = res.data.results;
          notices.map(e => {
            let agrees = e?.content?.results;
            if (Array.isArray(agrees) && agrees.length > 0) {
              e.agrees = agrees.map(item => item.user).join("，");
            }
            return e;
          });

          console.log("data.notices", notices);

          this.noticeList = notices;
          // this.$emit("update:open1", this.noticeList.length ? true : false);
          this.count = res.data.count;
        })
        .catch(err => {});
    },
    //任务提醒已读
    getNoticeRead(item) {
      console.log(item);
      this.showEventBox = false;
      getNoticeRead({ noticeid: item.id })
        .then(res => {
          console.log(res);
          this.getNoticeList();
        })
        .catch(err => {});
    },
    //任务变更同意
    postApprove(id) {
      postApprove({ noticeid: id })
        .then(res => {
          console.log(res);
          this.getNoticeList();
        })
        .catch(err => {});
    },
    noticeApprove(id) {
      noticeApprove({ noticeid: id })
        .then(res => {
          console.log(res);
          this.getNoticeList();
        })
        .catch(err => {});
    },
    noticeRefuse(id) {
      noticeRefuse({ noticeid: id })
        .then(res => {
          console.log(res);
          this.getNoticeList();
        })
        .catch(err => {});
    },
    clickReject(notice, type = "") {
      this.rejectType = type;
      this.mask.show = true;
      this.mask.data = notice;
    },
    clickEdit(notice) {
      this.mask.noticeid = notice.id;
      getNoticeTaskInfo({
        noticeid: notice.id
      })
        .then(res => {
          this.mask.showEdit = true;
          const tmpData = res.data;
          tmpData.times = [tmpData.plan_start_time, tmpData.plan_end_time];
          tmpData.startTimeChanged = false; // 开始时间是否改变
          tmpData.endTimeChanged = false; // 结束时间是否改变
          tmpData.pct2 = parseInt(tmpData.pct * 100);
          this.mask.data = tmpData;
        })
        .catch(err => {});
    },
    dataChanged() {
      this.getNoticeList();
      // commitChange({
      //   noticeid: this.mask.noticeid
      // })
      //   .then(res => {
      //     // Message.success("修改成功");
      //     this.getNoticeList();
      //   })
      //   .catch(err => {});
      // console.log("dataChanged...");
    },
    responseAbnormalTask(obj) {
      responseAbnormalNotice(obj)
        .then(res => {
          this.getNoticeList();
        })
        .catch(err => {});
    },
    scoreTask(item) {
      this.mask.showScore = true;
      this.mask.scoreData = item;
    },
    rejectCompletedTask(obj) {
      this.rejectType = "";
      this.mask.show = true;
      obj.finished = true;
      this.mask.data = obj;
    },
    load() {
      console.log(1111);
    },
    reload() {
      // location.reload();
      // 重新渲染
      // this.render = false
      // this.$nextTick(() => {
      //     this.render = true
      // })
    },
    //关闭
    activeOpen() {
      // this.$emit("update:open1", !this.open1);
    }
  },
  destroyed() {
    // 销毁监听
  }
};
</script>

<style lang="scss" scoped>
.list-move, /* 对移动中的元素应用的过渡 */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* 确保将离开的元素从布局流中删除
  以便能够正确地计算移动的动画。 */
.list-leave-active {
  position: absolute;
}

.task-reminder_min {
  height: 265px !important;
}
.grow {
  flex-grow: 2;
}
.task-reminder {
  background: #fff;
  background-size: 10px 5px;
  border-radius: 24px;
  box-shadow: 0px 0px 12px 0px #e8f0fb;
  overflow: auto;
  .conten {
    height: calc(100% - 32px);
    // height: vh(332);
    // border: 1px solid #000;
    padding: 0 vw(24) 0 vw(24);
    overflow-y: auto;
    position: relative;
    @include hideScrollbar;
    .top {
      font-size: vh(18);
      color: #103268;
      display: flex;
      align-items: center;
      position: sticky;
      top: 0;
      background: #ffffff;
      z-index: 9;
      padding-top: vh(24);
      padding-bottom: vh(6);
      box-shadow: -15px 0px 6px 0px #fff;
      span {
        font-size: vh(10);
        // width: vw(16);
        padding: 0px 3px;
        height: 16px;
        min-width: 16px;
        border-radius: 8px;
        background: #3787ff;
        text-align: center;
        color: #fff;
        line-height: 16px;
        margin-left: vw(8);
      }
    }
    .no-shadow {
      box-shadow: -8px 8px 15px transparent !important;
    }
    .list,
    .list2 {
      margin-top: vh(8);
      padding: vh(16);
      background: #f4f9ff;
      border-radius: 12px;
      font-size: vh(12);
      position: relative;
      box-shadow: -8px 8px 15px #e0eeee;
      // height: 203px;
      // border: 1px solid #000;
      cursor: pointer;
      &_item {
        display: flex;
        align-items: flex-start;
        color: #103268;
        padding-top: vh(8);
        // border: 1px solid #000;
        .state {
          color: rgba(16, 50, 104, 0.6);
          text-wrap: nowrap;
        }
        .time span {
          color: #0d68fd;
        }
        .file-list {
          display: flex;
          /deep/ .file {
            width: 75px;
          }
          // .state{
          //   width: 80px;
          // }
        }
        .detail-info {
          max-width: 60%;
        }
      }
      &_item:nth-child(1) {
        // border: 1px solid #000;
        padding-top: 0;
      }
      .title_max_w {
        max-width: 86%;
      }
      &_item-title {
        font-size: vh(16);
        .state {
          font-size: vh(10);
          // border: 1px solid #000;
          width: 56px;
          height: vh(22);
          border-radius: 16px;
          text-align: center;
          line-height: vh(22);
          color: #0d68fd;
          background: linear-gradient(
            90deg,
            rgba(73, 167, 255, 0.1) 0%,
            rgba(71, 119, 255, 0.1) 100%
          );
          margin-right: vw(8);
        }
      }
      .btn_known {
        // height:265px;
        width: vh(80);
        height: vh(24);
        text-align: center;
        line-height: vh(24);
        color: #0d68fd;
        background: linear-gradient(
          90deg,
          rgba(73, 167, 255, 0.1) 0%,
          rgba(71, 119, 255, 0.1) 100%
        );
        border-radius: 16px;
        cursor: pointer;
        user-select: none;
        position: absolute;
        right: 12px;
        bottom: 15px;
      }
      &_bottom {
        // border: 1px solid #000;
        margin-top: 12px;
        border-top: 1px solid rgba(24, 110, 255, 0.05);
        padding: vh(12) vw(8) 0;
        display: flex;
        justify-content: space-between;
        .btn {
          // height:265px;
          width: vh(90.67);
          height: vh(24);
          text-align: center;
          line-height: vh(24);
          color: #0d68fd;
          background: linear-gradient(
            90deg,
            rgba(73, 167, 255, 0.1) 0%,
            rgba(71, 119, 255, 0.1) 100%
          );
          border-radius: 16px;
          cursor: pointer;
          user-select: none;
        }
      }
      &_read {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 16px;
        top: 12px;
        .unread {
          position: absolute;
          z-index: 2;
          min-width: 2px;
          padding: 0 3px;
          height: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          background: red;
          color: #fff;
          font-size: 12px;
          transform: translate(18px, -200%);
          border: 2px solid #fff;
        }
      }
    }
    .list2 {
      cursor: auto;
      .pointer {
        cursor: pointer;
      }
    }
    .list:nth-child(1) {
      margin-top: 0px;
      // border: 1px solid #000;
      margin-top: vh(16);
    }
  }
  .collapse {
    // border: 1px solid #000;
    height: vh(32);
    border-radius: 0 0 24px 24px;
    .open {
      width: 16px;
      height: 16px;
      margin: 0 auto;
      // border: 1px solid #000;
      img {
        // display: block;
        margin-top: 7px;
      }
    }
  }
}
.accomplishTask {
  .state {
    // border: 1px solid #000;
    color: #08ba91 !important;
    background: linear-gradient(
      247deg,
      rgba(107, 225, 198, 0.15) 14.85%,
      rgba(101, 211, 186, 0.15) 85.15%
    ) !important;
  }
}
.accomplishBtn {
  .btn {
    width: 144px !important;
    cursor: pointer;
    user-select: none;
  }
  .btn:nth-child(2) {
    background: rgba(253, 102, 105, 0.1);
    color: #fd6669 !important;
  }
}

.abnormityTask {
  .state {
    // border: 1px solid #000;
    color: #fd6669 !important;
    background: linear-gradient(
      247deg,
      rgba(107, 225, 198, 0.15) 14.85%,
      rgba(101, 211, 186, 0.15) 85.15%
    ) !important;
    color: #08ba91 !important;
  }
}
.abnormityhBtn {
  .btn {
    width: 144px !important;
    cursor: pointer;
    user-select: none;
  }
  .btn:nth-child(2) {
    background: linear-gradient(
      247deg,
      rgba(107, 225, 198, 0.15) 14.85%,
      rgba(101, 211, 186, 0.15) 85.15%
    ) !important;
    color: #fd6669 !important;
  }
}
.infinite-list {
  padding-bottom: 10px;
}
.infinite-list::-webkit-scrollbar {
  width: 0;
}
img {
  width: 100%;
  height: 100%;
}

.event-box {
  position: fixed;
  min-height: 300px;
  top: 25px;
  right: vw(445);
  z-index: 99;
  opacity: 0;
  transition-delay: 0.3s;
  transition-duration: 1.5s;
  transition-property: opacity;
  // transition-timing-function: ease-out;
}
.show-event-box {
  opacity: 1;
}
</style>